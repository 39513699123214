<template>
  <BillingWrapper>
    <sdCards>
      <a-row :gutter="25">
        <a-col :lg="12" :xs="24">
          <div class="d-flex flex-column">
            <h3 class="plan-details-title">
              Active until {{ getFormatedDate(currentPlan.expire_date) }}
            </h3>
            <p class="plan-details-message">
              We will send you a notification upon Subscription expiration
            </p>
          </div>
        </a-col>
        <a-col :lg="12" :xs="24">
          <div class="d-flex align-items-center justify-content-between">
            <div class="overview-box-single">
              <p>Users</p>
            </div>
            <div class="overview-box-single text-right">
              <p>
                {{ currentPlan.actual_participants }} of
                {{ currentPlan.total_participant }} Used
              </p>
            </div>
          </div>
          <a-progress
            :percent="Number(currentPlan?.participant_progress || 0)"
            :showInfo="false"
            class="progress-primary"
          />
          <!-- <div class="growth-upward">
            14 Users remaining until your plan requires update
          </div> -->
        </a-col>
        <a-col :lg="12" :xs="24" style="margin-top: 30px">
          <div class="d-flex flex-column">
            <span class="extra-plan-details">
              <span
                ><h2>{{ currentPlan.plan_name }}</h2></span
              >
            </span>
            <span class="extra-plan-details">
              <span>${{ currentPlan.price }}</span> Per Month
            </span>
            <!-- <span class="extra-plan-details">
              Extended Pro Package. Up to 100 Agents & 25 Projects
            </span> -->
          </div>
        </a-col>
        <a-col :lg="12" :xs="24" class="plan-button-container">
          <sdButton
            size="small"
            type="light"
            style="margin-right: 10px"
            @click="cancelPlan"
          >
            Cancel Subscription
          </sdButton>
          <sdButton @click="showPlansModal" size="small" type="primary">
            Upgrade Plan
          </sdButton>
        </a-col>
      </a-row>
    </sdCards>
    <!-- <sdCards title="Payment Methods">
      <h3>My Cards</h3>
      <a-row :gutter="15" class="payment_methods">
        <a-col :lg="12" :xs="24">
          <sdCards
            headless
            :bodyStyle="{
              padding: '15px !important',
              border: '1px dashed #e4e6ef',
              borderRadius: '5px',
            }">
            <h4>Marcus Morris</h4>
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <img class="card-image-cls" :src="require('@/assets/img/cards-logo/card-visa.svg')" />
                <div>
                  <div class="card-number">Visa **** 1679</div>
                  <div class="card-exp-date">Card expires at 09/24</div>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <sdButton size="small" type="light" style="margin-right: 10px;">
                  Delete
                </sdButton>
                <sdButton size="small" type="light">
                  Edit
                </sdButton>
              </div>
            </div>
          </sdCards>
        </a-col>
        <a-col :lg="12" :xs="24">
          <sdCards
            headless
            :bodyStyle="{
              padding: '15px !important',
              border: '1px dashed #e4e6ef',
              borderRadius: '5px',
            }"
          >
            <h4>Marcus Morris</h4>
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <img class="card-image-cls" :src="require('@/assets/img/cards-logo/card-american-express.svg')" />
                <div>
                  <div class="card-number">American Express **** 1679</div>
                  <div class="card-exp-date">Card expires at 09/24</div>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <sdButton size="small" type="light" style="margin-right: 10px;">
                  Delete
                </sdButton>
                <sdButton size="small" type="light">
                  Edit
                </sdButton>
              </div>
            </div>
          </sdCards>
        </a-col>
        <a-col :lg="12" :xs="24">
          <sdCards
            headless
            :bodyStyle="{
              padding: '15px !important',
              border: '1px dashed #e4e6ef',
              borderRadius: '5px',
            }"
          >
            <h4>Marcus Morris</h4>
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <img class="card-image-cls" :src="require('@/assets/img/cards-logo/card-ms.svg')" />
                <div>
                  <div class="card-number">Mastercard **** 1679</div>
                  <div class="card-exp-date">Card expires at 09/24</div>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <sdButton size="small" type="light" style="margin-right: 10px;">
                  Delete
                </sdButton>
                <sdButton size="small" type="light">
                  Edit
                </sdButton>
              </div>
            </div>
          </sdCards>
        </a-col>
        <a-col :lg="12" :xs="24">
          <sdCards
            headless
            :bodyStyle="{
              padding: '15px !important',
              border: '1px dashed #e4e6ef',
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: '#C72C5105'
            }"
          >
            <sdButton size="small" type="primary">
              Add Card
            </sdButton>
          </sdCards>
        </a-col>
      </a-row>
    </sdCards> -->
    <!-- <sdCards title="Billing Address">
      <a-row :gutter="15" class="payment_methods">
        <a-col :lg="12" :xs="24">
          <sdCards
            headless
            :bodyStyle="{
              padding: '15px !important',
              border: '1px dashed #e4e6ef',
              borderRadius: '5px',
            }">
            <h4>Address 1</h4>
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="card-exp-date">Ap #285-7193 Ullamcorper Avenue<br>Amesbury HI 93373<br>US</div>
              </div>
              <div class="d-flex align-items-center">
                <sdButton size="small" type="light" style="margin-right: 10px;">
                  Delete
                </sdButton>
                <sdButton size="small" type="light">
                  Edit
                </sdButton>
              </div>
            </div>
          </sdCards>
        </a-col>
        <a-col :lg="12" :xs="24">
          <sdCards
            headless
            :bodyStyle="{
              padding: '15px !important',
              border: '1px dashed #e4e6ef',
              borderRadius: '5px',
            }">
            <h4>Address 2</h4>
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="card-exp-date">Ap #285-7193 Ullamcorper Avenue<br>Amesbury HI 93373<br>US</div>
              </div>
              <div class="d-flex align-items-center">
                <sdButton size="small" type="light" style="margin-right: 10px;">
                  Delete
                </sdButton>
                <sdButton size="small" type="light">
                  Edit
                </sdButton>
              </div>
            </div>
          </sdCards>
        </a-col>
        <a-col :lg="12" :xs="24">
          <sdCards
            headless
            :bodyStyle="{
              padding: '15px !important',
              border: '1px dashed #e4e6ef',
              borderRadius: '5px',
            }">
            <h4>Address 3</h4>
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="card-exp-date">Ap #285-7193 Ullamcorper Avenue<br>Amesbury HI 93373<br>US</div>
              </div>
              <div class="d-flex align-items-center">
                <sdButton size="small" type="light" style="margin-right: 10px;">
                  Delete
                </sdButton>
                <sdButton size="small" type="light">
                  Edit
                </sdButton>
              </div>
            </div>
          </sdCards>
        </a-col>
        <a-col :lg="12" :xs="24">
          <sdCards
            headless
            :bodyStyle="{
              padding: '15px !important',
              border: '1px dashed #e4e6ef',
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: '#C72C5105'
            }"
          >
            <sdButton size="small" type="primary">
              New Address
            </sdButton>
          </sdCards>
        </a-col>
      </a-row>
    </sdCards> -->
    <sdCards title="Billing History">
      <BillingTableWrapper>
        <div class="table-bordered table-responsive">
          <a-table
            rowKey="created_date"
            class="custom_table"
            :columns="fields"
            :data-source="invoices"
            @change="handleTableChange"
            :pagination="{
              pageSize: perPage,
              showSizeChanger: false,
              total: totalData,
            }"
          >
            <template #created_date="{ text: created_date }">
              <div v-if="created_date">
                {{ getFormatedDate(created_date) }}
              </div>
            </template>
            <template #invoice_status="{ text: invoice_status }">
              <span class="status Success" v-if="invoice_status == 'succeeded'"
                >Paid</span
              >
              <span class="status error" v-else>{{ status }}</span>
            </template>
          </a-table>
        </div>
      </BillingTableWrapper>
    </sdCards>
    <PlanModal ref="planModal" @onPlanPurchaseComplete="onComplete" />
  </BillingWrapper>
  <a-modal
    v-model:visible="cancelConfirmVisible"
    title="Plan cancel confirmation"
    :confirm-loading="isCanceling"
    :centered="true"
    ok-text="Remove"
    cancel-text="Cancel"
    @ok="handleDeleteModal"
    :ok-button-props="okButtonProps"
  >
    <p>Are you sure you want to cancel this plan?</p>
  </a-modal>
</template>

<script>
import moment from "moment";

import PlanModal from "@/components/modals/PlanModal/PlanModal.vue";
import { BillingWrapper, BillingTableWrapper } from "./style";
import { API } from "@/services/api";
import { theme } from "@/config/theme/themeVariables";
import Notification from "@/services/Notification";

export default {
  name: "Billing",
  components: {
    PlanModal,
    BillingWrapper,
    BillingTableWrapper,
  },
  computed: {
    planModal: function () {
      return this.$refs.planModal;
    },
  },
  data() {
    return {
      fields: [
        {
          title: "Date",
          dataIndex: "created_date",
          key: "created_date",
          slots: { customRender: "created_date" },
        },
        { title: "Plan", dataIndex: "name", key: "name" },
        { title: "Description", dataIndex: "description", key: "description" },
        { title: "Amount", dataIndex: "amount", key: "amount" },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          slots: {
            customRender: "invoice_status",
          },
        },
      ],
      subscription_id: "",
      user_id: "",
      currentPage: 1,
      perPage: 10,
      totalData: 0,
      invoices: [],
      currentPlan: [],
      cancelConfirmVisible: false,
      isCanceling: false,
      okButtonProps: {
        style: {
          backgroundColor: theme["primary-color"],
          borderWidth: 0,
        },
      },
    };
  },
  methods: {
    getDateFormat(date) {
      return moment(date).format("ll");
    },
    showPlansModal() {
      this.planModal.showPlansModal();
    },
    onComplete() {
      this.planModal.hidePlansModal();
      this.getSubscription();
      this.getInvoices();
    },
    async getSubscription() {
      try {
        const resData = await API.getSubscription();
        const { data } = resData?.data ?? {};
        const { stripe_subcription_id = "", stripe_customer_id = "" } =
          data ?? {};
        this.subscription_id = stripe_subcription_id;
        this.customer_id = stripe_customer_id;
        this.currentPlan = data ?? {};
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, { type: "error", title: "Error" });
        }
      }
    },
    async getInvoices() {
      try {
        const user_id = this.$store?.getters?.authUser?.encrypt_id ?? "";
        const params = {
          user_id: user_id,
          page: this.currentPage,
          limit: this.perPage,
        };
        this.user_id = user_id;
        const resData = await API.getInvoices({ params });
        const { data: iData, status, message } = resData.data;

        if (!status && message) {
          Notification.show(message, { type: "error", title: "Error" });
        } else {
          this.invoices = iData.invoices;
          this.totalData = iData.total_rows;
        }
      } catch (error) {}
    },
    async handleDeleteModal() {
      try {
        this.isCanceling = true;
        const params = {
          user_id: this.user_id,
          stripe_customer_id: this.customer_id,
          stripe_subscription_id: this.subscription_id,
        };
        const canRes = await API.cancelSubscription(params);
        const { status, message } = canRes.data;
        if (message) {
          Notification.show(message, {
            type: status ? "success" : "error",
            title: status ? "Success" : "Error",
          });
        }
        if (status) {
          await this.$store.dispatch("getProfile");
          this.$router.replace({ path: "pricing" });
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, { type: "error", title: "Error" });
        }
      } finally {
        this.isCanceling = false;
        this.cancelConfirmVisible = false;
      }
    },
    handleTableChange(page) {
      if (this.currentPage != page.current) {
        this.currentPage = page.current;
        setTimeout(() => {
          this.getInvoices();
        }, 300);
      }
    },
    cancelPlan() {
      this.cancelConfirmVisible = true;
    },
    getFormatedDate(date = "") {
      return moment(date).format("LL");
    },
  },
  created() {
    this.getSubscription();
    this.getInvoices();
  },
};
</script>
<style>
.table-bordered .ant-table-thead tr th:last-child,
.table-bordered .ant-table-tbody tr td:last-child {
  text-align: center !important;
}
</style>